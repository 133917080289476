<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full fixed md:static"
		>
			<span class="text-white ml-2 font-bold">{{
				firstLetterUpperCase($route.params.name.replaceAll("_", " "))
			}}</span>
			<div>
				<span class="text-white font-bold">Filtrar por estado: </span>
				<select
					v-model="estatusTemp"
					class="py-1 h-8 w-40 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none font-bold"
				>
					<option :value="null" selected>Todos</option>
					<option value="asignado">Asignado</option>
					<option value="reasignado">Reasignado</option>
					<option value="espera">Espera</option>
					<option value="ejecucion">Ejecución</option>
					<option value="completado">Completado</option>
					<option value="finalizado">Finalizado</option>
					<option value="entregado">Entregado</option>
					<option value="anulado">Anulado</option>
				</select>
			</div>
			<div>
				<span class="text-white font-bold">Filtrar por cobrado: </span>
				<select
					v-model="filtroCobradoSelectTemp"
					@change="condicionesFiltroCobrado()"
					class="py-1 h-8 w-40 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none font-bold"
				>
					<option :value="null">Todos</option>
					<option value="1">Cobrados</option>
					<option value="0">No cobrados</option>
				</select>
			</div>
			<div class="flex items-center justify-center">
				<router-link
					:to="{
						name: 'EditarCliente',
						params: {
							id: $route.params.id
						}
					}"
				>
					<button
						@click="$emit('click')"
						class="text-white font-bold py-2 px-4 inline-flex items-center focus:outline-none"
					>
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							class="h-6 w-6 mr-2 hover:opacity-100 cursor-pointer fill-current text-white"
						>
							<path
								d="M4.17473 2.06016C5.68679 0.550767 8.13538 0.550767 9.64744 2.06016L11.0174 3.4376L25.6002 18.0127L25.6312 18.0439C25.6387 18.0514 25.6387 18.0594 25.6467 18.0594C25.6622 18.0826 25.6854 18.1056 25.7007 18.1289C25.7007 18.1366 25.7087 18.1366 25.7087 18.1444C25.7242 18.1676 25.7317 18.1831 25.7474 18.2064C25.7549 18.2141 25.7549 18.2216 25.7627 18.2296C25.7704 18.2529 25.7782 18.2684 25.7861 18.2916C25.7861 18.2991 25.7936 18.2991 25.7936 18.3071L29.0291 28.0366C29.124 28.3136 29.0519 28.6203 28.8434 28.8259C28.6969 28.9704 28.4994 29.0513 28.2938 29.0506C28.2098 29.0491 28.1265 29.036 28.0461 29.0118L18.3242 25.7686C18.3166 25.7686 18.3166 25.7686 18.3087 25.7611C18.2842 25.7538 18.2607 25.7434 18.2392 25.7299C18.2331 25.7291 18.2278 25.7265 18.2234 25.7224C18.2004 25.7069 18.1694 25.6911 18.1462 25.6756C18.1232 25.6604 18.0997 25.6371 18.0765 25.6216C18.0687 25.6136 18.0612 25.6136 18.0612 25.6061C18.0532 25.5984 18.0377 25.5909 18.03 25.5751L2.07722 9.62239C0.567829 8.11033 0.567829 5.66174 2.07722 4.14992L4.17473 2.06016ZM18.5718 23.9495L23.9591 18.5625L10.4755 5.07895L5.08826 10.4659L18.5718 23.9495ZM24.7179 19.9944L20.0115 24.7006L27.0786 27.0535L24.7179 19.9944ZM3.16847 8.53865L3.98903 9.36695L9.3765 3.97947L8.54796 3.15141C7.64169 2.24587 6.17273 2.24587 5.26622 3.15141L3.16096 5.25667C2.26148 6.16705 2.26487 7.63262 3.16847 8.53865Z"
							/>
						</svg>
						<span class="text-white underline">Editar cliente</span>
					</button>
				</router-link>
			</div>
		</div>
		<div class="md:flex justify-between md:mt-0 mt-20">
			<div
				class="w-80 ml-5 border-gray-300 border h-32 rounded-lg grid items-center"
			>
				<div class="flex ml-12">
					<div>
						<span class="font-bold mr-2">Fecha Inicio:</span>
						<input
							class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							type="date"
							v-model="fechaInicioTemp"
						/>
					</div>
					<div class="w-36">
						<span class="font-bold mx-2">Fecha Fin:</span>
						<input
							class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							type="date"
							v-model="fechaFinTemp"
						/>
					</div>
				</div>
				<div class="flex justify-around">
					<Botonp
						class="underline"
						botonpText="Reset"
						@click="resetData()"
					></Botonp>
					<Botonp
						class="underline"
						botonpText="Buscar"
						@click="nuevaBusqueda()"
					></Botonp>
				</div>
			</div>
			<div class="mx-3">
				<div v-if="filtroFecha" class="flex">
					<div
						class="flex flex-wrap content-center justify-end mt-10 md:mt-4 mb-1"
					>
						<div class="flex justify-center content-center">
							<div class="mr-3 flex content-center flex-wrap">
								<div class="">Total cobrado USD:</div>
							</div>
							<Campo :campoCoti="formato(totalCobradoUSD)" class="mr-10" />
						</div>
					</div>
					<div
						class="flex flex-wrap content-center justify-end mt-10 md:mt-4 mb-1"
					>
						<div class="flex justify-center content-center">
							<div class="mr-3 flex content-center flex-wrap">
								<div class="">Total cobrado Bs:</div>
							</div>
							<Campo :campoCoti="formato(totalCobradoBs)" class="mr-10" />
						</div>
					</div>
				</div>
				<div class="flex">
					<div
						class="flex flex-wrap content-center justify-end mt-10 md:mt-4 mb-1"
					>
						<div class="flex justify-center content-center">
							<div class="mr-3 flex content-center flex-wrap">
								<div class="">Total deuda USD:</div>
							</div>
							<Campo :campoCoti="formato(totalDeudaUSD)" class="mr-10" />
						</div>
					</div>
					<div
						class="flex flex-wrap content-center justify-end mt-10 md:mt-4 mb-1"
					>
						<div class="flex justify-center content-center">
							<div class="mr-3 flex content-center flex-wrap">
								<div class="">Total deuda Bs:</div>
							</div>
							<Campo :campoCoti="formato(totalDeudaBs)" class="mr-10" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex justify-center my-4">
			<span v-if="showMessage">{{ message }}</span>
		</div>
		<div class="flex justify-center">
			<table id="pedidos" class="table-auto">
				<thead>
					<tr>
						<th class="border-2 px-2 py-2"># Orden de compra</th>
						<th class="border-2 px-4 py-2">Vendedor</th>
						<th class="border-2 px-4 py-2">Total Bs</th>
						<th class="border-2 px-4 py-2">Total $</th>
						<th class="border-2 px-4 py-2">Fecha Entrega</th>
						<th class="border-2 px-4 py-2">Estado</th>
						<th class="border-2 px-4 py-2">Cobrado</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(pedido, k) in detalleCliente" :key="k">
						<td class="text-center border-2 px-2 py-4">
							<router-link
								class="hover:text-blue-500 hover:underline"
								:to="{
									name: 'PedidoID',
									params: {
										id: pedido.id,
										nombre: $route.params.name,
										telefono: $route.params.phone,
										estatus: pedido.estatus,
										cobrado: pedido.cobrado
									}
								}"
							>
								<div>{{ pedido.id.padStart(4, "0") }}</div></router-link
							>
						</td>
						<td class="border-2 px-4 py-1">
							{{ firstLetterUpperCase(pedido.name) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ formato(pedido.total_factura * pedido.tasa_dia) }}
						</td>
						<td class="border-2 px-4 py-4 text-center">
							{{ formato(pedido.total_factura) }}
						</td>
						<td class="border-2 px-4 py-4 text-center">
							{{ formatoFecha(pedido.fecha_entrega) }}
						</td>
						<td class="border-2 px-4 py-4 text-center">
							{{ firstLetterUpperCase(pedido.estatus) }}
						</td>
						<td class="border-2 px-4 py-4 text-center">
							<div
								class="w-20"
								type="text"
								:class="pedido.cobrado == 1 ? 'text-green-400' : 'text-red-400'"
							>
								<div v-if="pedido.cobrado == 1">Cobrado</div>
								<div v-else>No cobrado</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex justify-center my-2">
			<span v-if="showMessage">{{ message }}</span>
		</div>
		<div class="flex justify-between">
			<div class="pl-32 pt-2 pb-6">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="dataInicio > 0"
					@click="cargarDataAnterior()"
				>
					Anterior
				</button>
			</div>
			<div class="pr-40 pt-2 pb-6">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="continuaData"
					@click="cargarDataSiguiente()"
				>
					Siguiente
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { formato, firstLetterUpperCase, formatoFecha } from "@/functions.js";
import Botonp from "@/components/botonPrimario";
import Campo from "@/components/camposCotizacion";

export default {
	components: {
		Botonp,
		Campo
	},
	data() {
		return {
			showMessage: false,
			message: "",
			paginacion: 10,
			dataInicio: 0,
			dataFin: 0,
			cobradoTemp: false,
			cobrado: false,
			filtroCobradoTemp: true,
			filtroCobradoSelectTemp: "0",
			filtroCobrado: true,
			filtroCobradoSelect: "0",
			filtroFecha: false,
			fechaInicio: null,
			fechaFin: null,
			filtroFechaTemp: false,
			fechaInicioTemp: null,
			fechaFinTemp: null,
			estatusTemp: null,
			estatus: null,
			detalleCliente: [],
			totalCobradoBs: 0,
			totalCobradoUSD: 0,
			totalDeudaBs: 0,
			totalDeudaUSD: 0,
			continuaData: false,
			detalleClienteVar: []
		};
	},
	apollo: {
		detalleClienteVar: {
			query: require("@/graphql/queries/detalleClienteQuery.gql"),
			variables() {
				// Use vue reactive properties here
				return {
					user_id: this.$route.params.id,
					dataInicio: this.dataInicio,
					dataFin: this.dataFin,
					cobrado: this.cobrado,
					filtroCobrado: this.filtroCobrado,
					filtroFecha: this.filtroFecha,
					fechaInicio: this.fechaInicio,
					fechaFin: this.fechaFin,
					estatus: this.estatus
				};
			},
			fetchPolicy: "cache-and-network",
			update: data => data.detalleCliente,
			error: (error, vm) => (
				(this.showMessage = true),
				(this.message = "Error en carga, por favor vuelva a intentarlo")
			),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	watch: {
		detalleClienteVar: function detalleClienteWatcher() {
			this.detalleCliente = this.detalleClienteVar.resultado_cliente;
			this.continuaData = this.detalleClienteVar.continua_data;
			this.totalCobradoBs = this.detalleClienteVar.cobrado_bs;
			this.totalCobradoUSD = this.detalleClienteVar.cobrado_usd;
			this.totalDeudaBs = this.detalleClienteVar.deuda_bs;
			this.totalDeudaUSD = this.detalleClienteVar.deuda_usd;
		}
	},
	created() {
		this.dataFin = this.paginacion;
	},
	methods: {
		formato,
		firstLetterUpperCase,
		formatoFecha,
		condicionesFiltroCobrado() {
			if (this.filtroCobradoSelectTemp === null) {
				this.filtroCobradoTemp = false;
			}
			if (this.filtroCobradoSelectTemp === "1") {
				this.filtroCobradoTemp = true;
				this.cobradoTemp = true;
			}
			if (this.filtroCobradoSelectTemp === "0") {
				this.filtroCobradoTemp = true;
				this.cobradoTemp = false;
			}
		},
		resetData() {
			this.dataInicio = 0;
			this.dataFin = this.paginacion;
			this.fechaInicioTemp = null;
			this.fechaFinTemp = null;
			this.filtroFechaTemp = false;
		},
		nuevaBusqueda() {
			//Validacion de data
			let fechaInicio = new Date(this.fechaInicioTemp);
			let fechaFin = new Date(this.fechaFinTemp);
			let ejecutarQuery = false;

			if (fechaInicio > fechaFin) {
				alert("La fecha de inicio tiene que ser antes de la fecha de fin");
			} else {
				if (this.fechaInicioTemp === null && this.fechaFinTemp === null) {
					this.dataInicio = 0;
					this.dataFin = this.paginacion;
					//this.filtro = "";
					this.fechaInicioTemp = null;
					this.fechaFinTemp = null;
					//this.filtroCobrado = "";
					this.detalleCliente = [];
					ejecutarQuery = true;
				} else {
					if (this.fechaInicioTemp === null || this.fechaFinTemp === null) {
						alert("Por favor elegir fecha de inicio y fin.");
					} else {
						ejecutarQuery = true;
						this.filtroFechaTemp = true;
					}
				}
			}
			if (ejecutarQuery) {
				this.cobrado = this.cobradoTemp;
				this.filtroCobrado = this.filtroCobradoTemp;
				this.filtroFecha = this.filtroFechaTemp;
				this.fechaInicio = this.fechaInicioTemp;
				this.fechaFin = this.fechaFinTemp;
				this.estatus = this.estatusTemp;
			}
		},
		cargarDataAnterior() {
			this.dataInicio -= this.paginacion;
			this.dataFin -= this.paginacion;
			this.detalleClienteQuery();
		},
		cargarDataSiguiente() {
			this.dataInicio += this.paginacion;
			this.dataFin += this.paginacion;
			this.detalleClienteQuery();
		}
	}
};
</script>

<style></style>
