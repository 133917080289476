<template>
  <div>
    <span class="text-botonT">{{campoTitulo}}</span>
    <input
      type="text"
      class="border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold py-1 px-2 inline-flex items-center focus:outline-none"
      :value="campoCoti"
      v-on:input="ingresarTasa($event)"
    />
  </div>
</template>

<script>
export default {
  name: "campod",
  props: ["campoCoti","campoTitulo"],
  methods: {
    ingresarTasa(event) {
      this.campoCoti = event.target.value;
    }
  }
};
</script>
